/* eslint-disable @typescript-eslint/no-type-alias */
// @flow

export enum ActiomModeENUM {
    ID = "ID",
    NAMING = "NAMING"
}

export type ActiomModeStrings = keyof typeof ActiomModeENUM

export enum ActionENUM {
    CREATE = "Create",
    UPDATE = "Update"
}

export type ActionStrings = "Create" | "Update";

export interface IResponseJSON {
    Successful: IResponseJSONItem[];
    Error: IResponseJSONItem[];
}

export interface IResponseJSONItem {
    ID?: string;
    Message?: string;
    NameIdOfNode?: string;
}

export const fileError = "The requested file could not be read, typically due to permission problems that have occurred after a reference to a file was acquired"
