/* eslint-disable react/react-in-jsx-scope */
import { CookieProvider } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import ReactDOM from "react-dom";
import { Helmet } from 'react-helmet';
import { ToastContainer } from "react-toastify";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

let CSP = REACT_ENV.REACT_APP_CONTENT_SECURITY_POLICY
CSP = CSP.replace(/self/gu, "'self'")
CSP = CSP.replace(/unsafe-inline/gu, "'unsafe-inline'")
CSP = CSP.replace(/unsafe-eval/gu, "'unsafe-eval'")

ReactDOM.render(
    <CookieProvider>
        <Helmet>
                <meta httpEquiv="Content-Security-Policy" content={CSP} />
        </Helmet>

        <App />

        <ToastContainer />
    </CookieProvider>,
    document.querySelector("#root")
);

serviceWorker.unregister();
