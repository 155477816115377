import { setStorage } from "cache";
import { toastError } from "components/Toastify";
import { useCookie } from "contexts/cookieContext";
import { PrimThemeProvider } from "contexts/themeContext";
import { REACT_ENV } from "environmentVariables";
import { SubscriptionLevelENUM } from "lib/axios/types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import Routes from "Routes";
import "sweetalert2/dist/sweetalert2.min.css";
import { base64_decode, compareHash } from "utils";
import { checkToken } from "utilsFn";
import "./i18n";

function App(): React.ReactElement {
    const { t } = useTranslation();

    const [isUserHaveAccess, setIsUserHaveAccess] = useState<undefined | boolean>(undefined);

    const { getAccessTokenCookie, getIDTokenCookie, getNonceCookie } = useCookie();
    const currentCookie = getAccessTokenCookie();
    const currentCookieIDToken = getIDTokenCookie();
    const checkNonceCookie = getNonceCookie();

    useEffect(() => {
        const forceLogout = (): void => {
            if (currentCookieIDToken) {
                const url = `${REACT_ENV.REACT_APP_OIDC_URL}/session/end?id_token_hint=${base64_decode(currentCookieIDToken)}&force=true&post_logout_redirect_uri=${window.location.origin}/login`
                window.location.replace(url);
                return
            }

            window.location.href = `${REACT_ENV.REACT_APP_UI_URL_3EDGES}/login?redirectURL=${window.location.origin}`
        }

        if(!currentCookieIDToken) {
            return forceLogout()
        }

        checkToken(base64_decode(currentCookieIDToken)).then((res: any) => {
            if (res.status !== "SUCCESS")
            {
                toastError(t("validations:session.expired"));
                forceLogout()
            } else {
                const { subtype, nonce, sub, subscriptionLevel } = res.verifiedToken

                if(!subscriptionLevel) {
                    console.log("Missing subscriptionLevel in ID Token payload. Free subscription level will be applied");
                }

                if (subscriptionLevel === SubscriptionLevelENUM.PRO || subscriptionLevel === SubscriptionLevelENUM.ENT) {
                    setIsUserHaveAccess(true)
                } else {
                    setIsUserHaveAccess(false)
                }

                if (nonce) {
                    // Check if the nonce code is different within cookie
                    compareHash(nonce, checkNonceCookie).then((err) => {
                        if (!res) {
                            toastError(t("validations:session.expired"));

                            forceLogout()
                        } else {
                            setStorage("user", { subtype, sub });
                        }
                    })
                } else {
                    setStorage("user", { subtype, sub });
                }
            }
        })

    }, [currentCookie]);

    // if (isUserHaveAccess === undefined) {
    //     return <div>Loading...</div>;
    // }

    // if (!isUserHaveAccess) {
    //     return (
    //         <PrimThemeProvider>
    //             <UpdateSubscriptionLevelPage />
    //         </PrimThemeProvider>
    //     )
    // }

    return (
        <PrimThemeProvider>
            <Routes />
        </PrimThemeProvider>
    );
}

export default App;
