/* eslint-disable max-lines */
/* eslint-disable react/jsx-props-no-spreading */
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField } from "@mui/material";
import { MainContainer, SwitchBoxStyled, SwitchButton, SwitchLabelStyled, Title } from "components/LoadData/styled";
import { ModalDialogYesNo } from "components/ModalDialogYesNo/ModalChange";
import { toastError, toastInfo, toastSuccess, toastWarning } from "components/Toastify";
import { useCookie } from "contexts/cookieContext";
import { apiServerInfo, endLoadTransactionRequest, loadDataRequest, startLoadTransactionRequest } from "lib/axios/requests";
import { IloadDataRequestBody } from "lib/axios/types";
import { CSVParseError, CSVWarningDuplicates, EndLoadTransactionError, LoadDataEror, StratLoadTransactionError } from "lib/types/customErrors";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LinearProgressWithLabel } from "ui-components/progrssBar";
import { defineCountOfIterations, dowloadFileHandler, parseCsv, toOrigin, validURL } from "utilsFn";
import { LimitOfTows } from "./constants";
import { ActiomModeENUM, ActiomModeStrings, ActionENUM, ActionStrings, IResponseJSON, fileError } from "./types";

interface LoadDataProps {
    apiServerUrl: string
    setApiServerUrl: (v: string) => void
    isApiServerConnected: boolean
    setIsApiServerConnected: (v: boolean) => void
}

export default function LoadData ({ apiServerUrl, setApiServerUrl, isApiServerConnected, setIsApiServerConnected }: LoadDataProps): React.ReactElement {
    const { t } = useTranslation();
    const { getAccessTokenCookie } = useCookie();

    const accessToken = getAccessTokenCookie();

    const [isloadingCheckServerUrl, setIsloadingCheckServerUrl] = useState<boolean>(false);

    const [action, setMode] = useState<ActionStrings>(ActionENUM.CREATE);
    const [actionMode, setActionMode] = useState<ActiomModeStrings>(ActiomModeENUM.ID);

    // const [targetNamesArray, setTargetNamesArray] = useState<string[] | null>(null);
    const [targetName, setTargetName] = useState<string>('');

    const [file, setFile] = useState<any>();
    const [fileName, setFileName] = useState<string>('');

    const [loadingLoadData, setLoadingLoadData] = useState<boolean>(false);
    const [showCancelButton, setShowCancelButton] = useState<boolean>(false);

    const [showProggresBar, setShowProggresBar] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    const [responseJSON, setResponseJSON] = useState<IResponseJSON>({
        Successful: [],
        Error: []
    });

    const [errorQueue, setErrorQueue] = useState<string[]>([]);
    const [terminateLoad, setTerminateLoad] = useState<boolean>(false);

    const terminateLoadRef = useRef<boolean>();
    terminateLoadRef.current = terminateLoad;

    const [isDuplicatesModalOpen, setIsDuplicatesModalOpen] = useState<boolean>(false);


    const hadnleClickConnectApiServer = (): void => {
        if (isApiServerConnected) {
            setIsApiServerConnected(false)
            // setTargetNamesArray(null)
            // setTargetName('')

            toastInfo(t("loadData:server.disconnected"))
        } else {
            setIsloadingCheckServerUrl(true)

            apiServerInfo(accessToken, apiServerUrl).then((data) => {
                if(data.isUp) {
                    // setTargetNamesArray(data.apiServerObjects)

                    setIsloadingCheckServerUrl(false)
                    setIsApiServerConnected(true)

                    toastSuccess(t("loadData:server.connected"))
                } else if (data.message) {
                    setIsloadingCheckServerUrl(false)

                    toastError(data.message)
                } else {
                    setIsloadingCheckServerUrl(false)

                    toastError(t("loadData:something.went.wrong"))
                }
            }).catch((error) =>
            {
                console.error(error);
                setIsloadingCheckServerUrl(false)

                toastError(t("loadData:something.went.wrong"))
            })
        }
    }

    const isAllowedToLoadData = (): boolean => {
        if (apiServerUrl && isApiServerConnected && targetName && file) {
            return true
        }

        return false
    }

    const resetStates = (): void => {
        setProgress(0)

        setShowCancelButton(false)
        setShowProggresBar(false)
        setTerminateLoad(false)

        setResponseJSON({
            Successful: [],
            Error: []
        })
        setErrorQueue([])
    }

    const handleLoadData = async (acceptDublicates = false): Promise<any> => {
        let currentRowIndex = 0

        try {
            setLoadingLoadData(true)
            setTerminateLoad(false)

            const fileRows = await file.text()

            const csvInfo = parseCsv(fileRows, targetName)

            const objectToSendInfo = {
                action: action,
                acceptDublicates: acceptDublicates,
                ...csvInfo
            }

            const transactionResponse = await startLoadTransactionRequest(apiServerUrl, accessToken, objectToSendInfo)

            if (transactionResponse.message) {
                throw new StratLoadTransactionError(transactionResponse.message)
            }

            if (transactionResponse.warning && transactionResponse.warning instanceof CSVWarningDuplicates) {
                setIsDuplicatesModalOpen(true)
                return;
            }

            setShowCancelButton(true)
            setShowProggresBar(true)

            for await (const _ of defineCountOfIterations(objectToSendInfo.rows.length, LimitOfTows)) {
                if (terminateLoadRef.current) {
                    toastSuccess(t("loadData:load.data.has.been.terminated"))
                    break
                }

                const loadDataBody: IloadDataRequestBody = {
                    serverURL: apiServerUrl,
                    fileName: objectToSendInfo.fileName,
                    rows: objectToSendInfo.rows.slice(currentRowIndex, currentRowIndex+LimitOfTows),
                    mode: actionMode,
                    action
                }

                const loadData = await loadDataRequest(accessToken, transactionResponse.transactionID, loadDataBody)

                if(loadData.message) {
                    throw new LoadDataEror(loadData.message)
                }

                if(loadData.data) {
                    loadData.data.forEach(node => {
                        if(node.Message) {
                            if (!errorQueue.includes(node.Message)) {
                                errorQueue.push(node.Message)

                                if(node.Message === `Fail to create object. ${targetName} already exists`) {
                                    toastWarning(t("loadData:some.duplicates.found"))
                                } else {
                                    toastWarning(`${node.Message}. ${t("loadData:please.see.more.in.json")}`)

                                    setTimeout(() => {
                                        const index = errorQueue.indexOf(node.Message);
                                        if (index !== -1) {
                                            errorQueue.splice(index, 1);
                                        }

                                    }, 5000)
                                }
                            }
                            responseJSON.Error.push(node)
                          } else {
                            responseJSON.Successful.push(node)
                          }
                    })
                }

                currentRowIndex += loadDataBody.rows.length
                setProgress(Math.floor(progress + (100 / objectToSendInfo.rows.length) * currentRowIndex))
            }

            const endTransactionResponse = await endLoadTransactionRequest(apiServerUrl, accessToken, transactionResponse.transactionID)

            if(endTransactionResponse.message) {
                throw new EndLoadTransactionError(endTransactionResponse.message)
            }

            if (!terminateLoadRef.current) {
                toastSuccess(t("loadData:data.has.been.successfully.loaded"))
            }

            dowloadFileHandler(responseJSON, fileName.replace(".csv", ""))
            resetStates()
            setLoadingLoadData(false)
        } catch (error)
        {
            resetStates()
            setLoadingLoadData(false)

            if (error instanceof StratLoadTransactionError
                || error instanceof CSVParseError
                || error instanceof LoadDataEror
                || error instanceof EndLoadTransactionError)
            {
                toastError(error.message)
            } else if (error.message.toString().startsWith(fileError)) {
                setFile(null)
                setFileName('')

                toastError(t("loadData:file.upload.error"))
            } else {
                console.log(error);
                toastError(t("loadData:something.went.wrong"))
            }
        }
    }

    return (
        <MainContainer>
            <Title
                style={{ margin: "0 auto 34px auto", }}
            >
                {t("loadData:data.loader.tittle")}
            </Title>

            <Box
                sx={{
                    width: "600px",
                    margin: "0 auto",
                }}
            >

                <Stack
                    spacing={2}
                    direction="row"
                    sx={{
                        margin: "0px auto 0 auto",
                        width: "fit-content"
                    }}
                >

                    <TextField
                        id="outlined-basic"
                        label={t("loadData:target.api.server.url")}
                        variant="outlined"
                        size="small"
                        value={apiServerUrl}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setApiServerUrl(toOrigin(event.target.value));
                        }}
                        sx={{
                            width: "350px",
                            maxWidth: "350px"
                        }}
                        disabled={isApiServerConnected}
                    />

                    <LoadingButton
                        size="small"
                        onClick={hadnleClickConnectApiServer}
                        loading={isloadingCheckServerUrl}
                        variant="contained"
                        sx={{
                            width: "120px",
                            maxWidth: "120px",
                            height: "40px",
                            minHeight: "40px",
                            paddingTop: "0px",
                            marginRight: "auto",
                            paddingBottom: "0px",
                        }}
                        disabled={validURL(apiServerUrl) && !loadingLoadData ? false : true}
                        color={isApiServerConnected ? "error" : "primary"}
                    >
                        <span>{isApiServerConnected ? t("loadData:api.server.disconnect") : t("loadData:api.server.connect")}</span>
                    </LoadingButton>
                </Stack>

                <Stack
                    spacing={2}
                    direction="row"
                    sx={{
                        margin: "17px auto 0 auto",
                        width: "fit-content"
                    }}
                >

                    <TextField
                        id="outlined-basic"
                        label={t("loadData:target.type")}
                        variant="outlined"
                        size="small"
                        value={targetName}
                        sx={{
                            width: "350px",
                            maxWidth: "350px",
                            margin: "0 auto 0 auto"
                        }}
                        disabled
                    />

                    <Box
                        sx={{
                            margin: "17px auto 0 auto"
                        }}
                    >

                        <Button
                            variant="contained"
                            component="label"
                            sx={{
                                width: "120px",
                                maxWidth: "120px",
                                height: "40px",
                                minHeight: "40px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                            }}
                            disabled={loadingLoadData}
                        >
                            <span>{t("loadData:upload.file")}</span>

                            <input
                                type="file"
                                hidden
                                accept=".csv"
                                onChange={({ target }) => {
                                    if (target.files[0]) {
                                        setFile(target.files[0])
                                        setFileName(target.files[0].name)
                                        setTargetName(target.files[0].name.replace('.csv', ''))

                                        toastSuccess(`${t("loadData:file.select")} "${target.files[0].name}" ${t("loadData:file.selected")}`)
                                    }
                                }}
                                onClick={(event)=> { event.currentTarget.value = null }}
                                disabled={loadingLoadData}
                            />
                        </Button>

                        {file && fileName
                        ? (
                            <div
                                style={{
                                    margin: "2px auto 0 auto",
                                    textAlign: "center",
                                    fontSize: "14px"
                                }}
                            >
                                {fileName}
                            </div>
                         ) : null
                        }

                    </Box>

                </Stack>

                <SwitchBoxStyled
                    style={{
                        width: 'fit-content',
                        marginLeft: "25%",
                        marginTop: file && fileName ? "-6px" : "17px"
                    }}
                >

                    <SwitchLabelStyled
                        style={loadingLoadData ? { cursor: 'default' } : { cursor: 'pointer' }}
                        onClick={() => { setMode(ActionENUM.CREATE) }}
                    >
                        <b>
                            {t("loadData:create.mode")}
                        </b>
                    </SwitchLabelStyled>

                    <SwitchButton
                        checked={action === ActionENUM.UPDATE}
                        onChange={(event) => {
                            if(event.target.checked) {
                                setMode(ActionENUM.UPDATE)
                                setActionMode(ActiomModeENUM.ID)
                            } else {
                                setMode(ActionENUM.CREATE)
                            }
                        }}
                        disabled={loadingLoadData}
                    />

                    <SwitchLabelStyled
                        style={loadingLoadData ? { cursor: 'default' } : { cursor: 'pointer' }}
                        onClick={() => { setMode(ActionENUM.UPDATE) }}
                    >
                        <b>
                            {t("loadData:update.mode") }
                        </b>
                    </SwitchLabelStyled>
                </SwitchBoxStyled>

                <SwitchBoxStyled
                    style={{
                        width: 'fit-content',
                        marginTop: "3px",
                        marginLeft: "17%"
                    }}
                >
                    <SwitchLabelStyled
                        style={action === ActionENUM.UPDATE || loadingLoadData ? { cursor: 'default' } : { cursor: 'pointer' }}
                        onClick={() => {
                            if(action === ActionENUM.CREATE) {
                                setActionMode(ActiomModeENUM.ID)
                            }
                        }}
                    >
                        <b>
                            {t("loadData:relationships.by.id")}
                        </b>
                    </SwitchLabelStyled>

                    <SwitchButton
                        checked={actionMode === ActiomModeENUM.NAMING}
                        onChange={(event) => {
                            if(event.target.checked) {
                                setActionMode(ActiomModeENUM.NAMING)
                            } else {
                                setActionMode(ActiomModeENUM.ID)
                            }
                        }}
                        disabled={action === ActionENUM.UPDATE || loadingLoadData}
                    />

                    <SwitchLabelStyled
                        style={action === ActionENUM.UPDATE || loadingLoadData ? { cursor: 'default' } : { cursor: 'pointer' }}
                        onClick={() => {
                            if(action === ActionENUM.CREATE) {
                                setActionMode(ActiomModeENUM.NAMING)
                            }
                        }}
                    >
                        <b>
                            {t("loadData:relationships.by.naming.properties")}
                        </b>
                    </SwitchLabelStyled>
                </SwitchBoxStyled>



                <Stack
                    spacing={2}
                    direction="row"
                    sx={{
                        margin: "21px 57px 0 auto",
                        width: "fit-content"
                    }}
                >
                    {showCancelButton
                    ? (

                        <LoadingButton
                            size="small"
                            onClick={() => {
                                setTerminateLoad(true)
                                setShowProggresBar(false)
                            }}
                            loading={terminateLoad}
                            variant="contained"
                            color="error"
                            sx={{
                                width: "120px",
                                maxWidth: "120px",
                                height: "40px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                marginTop: "0px"
                            }}
                        >
                            <span>{t("loadData:load.cancel")}</span>
                        </LoadingButton>

                    ) : null}

                    <LoadingButton
                        size="small"
                        onClick={()=> {
                            handleLoadData()
                        }}
                        endIcon={<SendIcon />}
                        loading={loadingLoadData}
                        variant="contained"
                        sx={{
                            width: "120px",
                            maxWidth: "120px",
                            height: "40px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            marginTop: "0px"
                        }}
                        disabled={!isAllowedToLoadData()}
                    >
                        <span>{t("loadData:load.data")}</span>
                    </LoadingButton>
                </Stack>
            </Box>


            {showProggresBar
                ? (
                    <LinearProgressWithLabel
                        value={progress}
                        sx={{
                            width: "600px",
                            height: "7px",
                            borderRadius: "4px"
                        }}
                    />
                ) : null
            }

            <ModalDialogYesNo
                handleClickOption={() => {
                    setIsDuplicatesModalOpen(false)

                    handleLoadData(true)
                }}
                description={`The ${targetName} ${t("loadData:may.be.duplicates.modal")}`}
                isModalOpen={isDuplicatesModalOpen}
                onClose={() => {
                    setIsDuplicatesModalOpen(false)
                    resetStates()
                    setLoadingLoadData(false)
                }}
            />

        </MainContainer>
    );
}
