import React from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HistoryIcon from '@mui/icons-material/History';

export function IconList({
    imgName
}: {
    imgName: string;
}): React.ReactElement {
    switch (imgName) {
        case "Load data":
            return (
                <CloudUploadIcon 
                    sx={{
                        height: "19px",
                        weight: "100%",
                        marginRight: "6px"
                    }} 
                />
            )
        case "Logs":
            return (
                <HistoryIcon 
                    sx={{
                        height: "19px",
                        weight: "100%",
                        marginRight: "6px"
                    }} 
                />
            )
        default:
    }
}
