export const getHoursAgo = (hours: number): string => {
    return (Date.now() - hours * 60 * 60 * 1000).toString()
}

export const getDaysAgo = (days: number): string => {
    return (Date.now() - days * 24 * 60 * 60 * 1000).toString()
}

export const getNow = (): string => {
    return Date.now().toString()
}
