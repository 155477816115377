import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IconList } from 'ui-components/IconList';

const drawerWidth = 200;

export default function DrawerUI(): React.ReactElement {
    const location = useLocation();
    const history = useHistory();

    const pages = ['Load data', "Logs"]

    const isSelected = (namePage: string): boolean => {
        if (namePage === "Load data" && location.pathname === "/") {
            return true
        }

        if (namePage.toLowerCase() === location.pathname.slice(1)) {
            return true
        }

        return false

    }

    const routing = (namePage: string): string => {
        if (namePage === "Load data") {
            return ""
        }

        return namePage.toLowerCase()
    }

    return (
        <Drawer
                variant="permanent"
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
            <Toolbar sx={{ marginTop: '7px' }} />

            <Box sx={{ overflow: 'auto' }}>
                <List>
                    {pages.map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton
                                  onClick={(e) => {
                                    const target = e.target as HTMLTextAreaElement;
                                    history.push(`/${routing(target.textContent)}`)
                                  }}
                                >
                                <IconList imgName={text} />

                                <ListItemText
                                        primary={text}
                                        sx={{
                                          fontFamily: "IBM Plex Sans",
                                          fontSize: "28px",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "1.32",
                                          letterSpacing: "normal",
                                          fontWeight: "500",
                                          color: isSelected(text) ? "#6f2496" : "#000000"
                                        }}
                                    />
                            </ListItemButton>
                        </ListItem>
            ))}
                </List>
            </Box>
        </Drawer>
    );
}
