import { AppBar, Box, CssBaseline } from '@mui/material';
import Drawer from "components/Drawer";
import LoadData from 'components/LoadData';
import PanelHeader from "components/PanelHeader";
import React, { useState } from "react";
import { MainContainer, PageContainer } from "./styled";

interface LoadDataPageProps {
    apiServerUrl: string
    setApiServerUrl: (v: string) => void
    isApiServerConnected: boolean
    setIsApiServerConnected: (v: boolean) => void
}

export function LoadDataPage ({ apiServerUrl, setApiServerUrl, isApiServerConnected, setIsApiServerConnected }: LoadDataPageProps): React.ReactElement {
    return (
        // eslint-disable-next-line react/forbid-component-props
        <PageContainer className="resizeHeader">
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <PanelHeader
                        apiServerUrl={apiServerUrl}
                        isApiServerConnected={isApiServerConnected}
                     />
                </AppBar>

                <Drawer />

                <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: "64px", height: "100vh" }}>
                    <MainContainer>
                        <LoadData
                            apiServerUrl={apiServerUrl}
                            setApiServerUrl={setApiServerUrl}
                            isApiServerConnected={isApiServerConnected}
                            setIsApiServerConnected={setIsApiServerConnected}
                        />
                    </MainContainer>

                </Box>
            </Box>
        </PageContainer>
    );
}
