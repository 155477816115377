/* eslint-disable func-names */
/* eslint-disable no-useless-escape */
/* eslint-disable unicorn/no-for-loop */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable unicorn/prefer-object-from-entries */
/* eslint-disable unicorn/prefer-string-slice */
/* eslint-disable unicorn/better-regex */
import CryptoJS from "crypto-js";
import { REACT_ENV } from "environmentVariables";
import { JwksClient, TokenValidator } from "lib";
import { CSVParseError } from "lib/types/customErrors";
import { IUser } from "lib/types/types";
import { base64_decode, isEmpty } from "utils";
import isURL from 'validator/lib/isURL';

export const OIDC_Logout = (currentCookieIdToken: string): void =>
{
    window.location.href = `${REACT_ENV.REACT_APP_OIDC_URL}/session/end?id_token_hint=${base64_decode(currentCookieIdToken)}&post_logout_redirect_uri=${window.location.origin}/login`
};

export const verifyTokenHandler = async (token: string): Promise<any> => {
    try {
        const tokenValidator = new TokenValidator(
            { clientId: REACT_ENV.REACT_APP_OIDC_CLIENT_ID },
            REACT_ENV.REACT_APP_OIDC_URL,
            new JwksClient({
                jwksUri: REACT_ENV.REACT_APP_JWKS_URI,
                idpUrl: REACT_ENV.REACT_APP_OIDC_URL,
                REACT_APP_PKEY_COOKIE_NAME: REACT_ENV.REACT_APP_PKEY_COOKIE_NAME
            })
        );

        const verifiedToken = await tokenValidator.claim(token);

        return { status: "SUCCESS", verifiedToken };
    } catch (error) {
        console.log(error);
        return { status: "ERROR", error: error.name, message: error.message };
    }
}

export const isTrue = (value): any =>
{
    return (value &&  typeof value == "boolean") || value === "true" ? true : false
};

export const isFalse = (value): any => {
    return !isTrue(value)
};

export const encryptText = (value, secret = "x-secret"): any => CryptoJS.AES.encrypt(value, secret);

export const decryptText = (value, secret = "x-secret"): any => {
    const res = CryptoJS.AES.decrypt(value, secret);
    return res.toString(CryptoJS.enc.Utf8);
};

export const checkToken = async (token): Promise<any> => {
    return await verifyTokenHandler(token)
};

export const decodeToken = (token: string): any => {
    const tokenValidator = new TokenValidator(
        { clientId: REACT_ENV.REACT_APP_OIDC_CLIENT_ID },
        REACT_ENV.REACT_APP_OIDC_URL,
        new JwksClient({
            jwksUri: REACT_ENV.REACT_APP_JWKS_URI,
            idpUrl: REACT_ENV.REACT_APP_OIDC_URL,
            REACT_APP_PKEY_COOKIE_NAME: REACT_ENV.REACT_APP_PKEY_COOKIE_NAME
        })
    );

    const decoded = tokenValidator.decodeClean(token);

    return decoded
};

export const capitalizeFirstLetter = (string: string): string =>{
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const selectedUser = (idTokenCookie: string): IUser =>
{
    const payload = decodeToken(base64_decode(idTokenCookie))
    const { subtype, sub } = payload
    return { subtype, sub }
}

const fnCSV = (csv) => {
    const rows = csv.split('\n').map(item => item.trim()).filter(item => item);
    const data = [];

    for (let i = 1; i < rows.length; i++) {
        const row = rows[i]
        const columns: any = fnRow(row);
        data.push(columns);
    }

    return { header: rows[0], data };
  }

const fnRow = (row) => {
    const columns = [];
    let column = '';
    let isInQuotes = false;
    let quoteType = '';

    for (let i = 0; i < row.length; i++) {
        const char = row[i];
        const prevChar = row[i-1];
        const nextChar = row[i + 1];
        let repeated = false

        if (prevChar === "," && char === ',') {
            column += "";
        }

        if (!isInQuotes && (char === ',' || i === row.length - 1)) {
            repeated = true

            if (char !== ',') {
                column += char;
            }

            columns.push(column.trim());
            column = '';

        } else if (char === '"' || char === "'") {
            if (!isInQuotes) {
                isInQuotes = true;
                quoteType = char;
            } else if (isInQuotes && quoteType === char) {
                isInQuotes = false;
                quoteType = '';
            } else {
                column += char;
            }
        } else {
            column += char;
        }

        if (i === row.length - 1) {
            if (char === ',' && isEmpty(nextChar)) {
                columns.push("");
            } else {
                if (!repeated) {
                    columns.push(column.trim());
                }
            }
        }
    }

    return columns;
  }

export const parseCsv = (fileText: string, fileName: string): {
    fileName: string,
    rows: Array<Record<string, string>>,
    headers: Array<string>
} => {
    let entrada = fileText
    entrada = entrada.replace(/\\'/g, "_$1$_")
    entrada = entrada.replace(/\\"/g, "_$2$_")
    entrada = entrada.replace(/\"\"/g, "_$3$_")
    entrada = entrada.replace(/\\/g, "_$4$_")

    const ret = fnCSV(entrada);
    const { header } = ret
    let { data } = ret

    const parsedCsv = {
        fileName,
        rows: [],
        headers: null
      }

    parsedCsv.headers = header.split(",");

    data = data.map(line =>
    {
        line = line.map(col => {
            col = col.replace(/\_\$4\$\_/g, '')
            col = col.replace(/\_\$3\$\_/g, '\"')
            col = col.replace(/\_\$2\$\_/g, '\"')
            col = col.replace(/\_\$1\$\_/g, "'")

            return encodeURIComponent(col)
        })

        const resObject = line.reduce((acc, cur, index) => {
            const key = parsedCsv.headers[index]
            return { ...acc, [key]: cur }
        }, {})

        return resObject
    })

    parsedCsv.rows = data;

    parsedCsv.rows.forEach(row => {
        const total = Object.keys(row).length

        if (parsedCsv.headers.length !== total)
        {
            console.group()
            console.log('Headers', parsedCsv.headers);
            console.log('Row', row);
            console.groupEnd();

            throw new CSVParseError('Invalid CSV')
        }
    })

    return parsedCsv
}

export const toOrigin = (url: string): string => {
    if (!url) {
      return ""
    }

    const pathArray = url.split( '/' );
    const protocol = pathArray[0];
    const host = pathArray[2];
    const origin = protocol + '//' + host;

    if(!protocol || !host) {
      return url
    }

    return origin
  };

export const validURL = (str: string): boolean => {
    if (str.startsWith("http://localhost:")) {
        return true
    }

    return isURL(str)
}

export const defineCountOfIterations = (csvLength: number, banchSize: number): void[] => {
    const template = [];
    const counts = Math.ceil(csvLength / banchSize)
    template.length = counts

    return template
}

export const dowloadFileHandler = (data: any, name: string): void => {
    const a = document.createElement("a");

    a.href = URL.createObjectURL(new Blob([JSON.stringify(data, null, "\t")], {}));
    a.setAttribute("download", `${name}.json`);

    document.body.append(a);

    a.click();
    a.remove();
};
