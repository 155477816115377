import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { ConfirmButton, Container } from "./styled";

interface IModalDialogYesNo {
    isModalOpen: boolean;
    description: string;
    onClose: () => void;
    handleClickOption: () => void;
}

export function ModalDialogYesNo ({
    isModalOpen,
    description,
    onClose,
    handleClickOption
}: IModalDialogYesNo): ReactElement {
    const { t } = useTranslation();
    
    return (
        <Modal isOpen={isModalOpen} onClose={onClose} style={{ width: "600px" }}>
            <Container>
                <div className="description">
                    {description}
                </div>

                <div className="buttons">
                    <ConfirmButton
                        data-cy="ModalChangePage_btnYes"
                        color="rgb(40, 167, 69)"
                        onClick={() => {
                            handleClickOption();
                        }}
                    >
                        {t("validations:confirm.yes")}
                    </ConfirmButton>

                    <ConfirmButton
                        color="rgb(221, 51, 51)"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {t("validations:confirm.no")}
                    </ConfirmButton>
                </div>
            </Container>
        </Modal>
    );
}
