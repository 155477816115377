/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import React from 'react';

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number }): React.ReactElement {
    return (
        <Box 
        sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            width: "600px", 
            justifyContent: "center", 
            margin: "14px auto 0 auto",
        }}
        >
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>

            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round( props.value, )}%`}
                </Typography>
            </Box>
        </Box>
  );
}
