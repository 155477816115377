import { Dialog } from "@mui/material";
import { CloseIcon } from "assets";
import React, { ReactNode } from "react";
import { ContentPage, ModalCloseIcon, ModalInnerContainer } from "./styled";

export function Modal({
    isOpen,
    onClose,
    children,
    style,
    className
}: {
    style?: React.CSSProperties;
    className?: string;
    isOpen: boolean;
    onClose: (event: React.MouseEvent<HTMLImageElement>, reason?: "backdropClick" | "escapeKeyDown") => void;
    children: ReactNode;
}): React.ReactElement {
    return (
        <Dialog open={isOpen} onClose={onClose} className={className}>
            <ModalCloseIcon src={CloseIcon} onClick={onClose} />

            <ModalInnerContainer style={style}>
                <ContentPage>{children}</ContentPage>
            </ModalInnerContainer>
        </Dialog>
    );
}
